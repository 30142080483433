@import "../../common";

.disconnectBtn {
  border-radius: 4px;
  border: solid 1px $primary-color;
  background: transparent;
  font-family: "Helvetica Neue LT Std";
  font-size: 14px;
  align-items: center;
  display: table-cell;
  vertical-align: middle;

  &:hover, &:focus, &:active {
    background-color: transparent !important;
    border-color: $primary-color;
    box-shadow: 0 0 0 0.25rem rgba($primary-color, .5) !important;
  }

  &:disabled {
    background-color: transparent !important;
    border-color: rgba($primary-color, 0.6);
    box-shadow: 0 !important;
  }
}