@import "../../common";

.liquidityChallenge{
  box-shadow: 0px 4px 16px $primary-color;
  border-radius: 30px;

  p{
    font-size: 15px !important;
  }
}

