@import "../../common";

.sectionTitle{
  color: $light-color;
  font-family: 'Wild World';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 140%;
  /* or 67px */

  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media (max-width: 1200px){
    font-size: 25px;
  }

  @media (max-width: 768px){
    font-size: 20px;
  }
}
.sectionParagraph{
  color: $light-color;
  font-family: 'Helvetica Neue LT Std';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;

  &.spacing{
    line-height: 180%;
  }

  @media (max-width: 768px){
    font-size: 15px;
  }
}
.project-banner{
  //height: 420px;
  //background-position: top !important;
  margin-top: 85px;
  //
  //@media (max-width: 1204px){
  //  height: 200px;
  //  background-size: 250%;
  //  background-repeat: no-repeat;
  //}

  .launch-type{
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    color: #000;
    text-transform: capitalize;
    background: $primary-color;
    border-radius: 10px;
    height: 38px;
  }
}

.underTitleFeatures{
  div{
    color: #FFF;
    background: rgba(100, 100, 100, 0.3);
    border-radius: 10px;
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 500;
    border: solid 1px #3F4040;
    font-size: 15px;
    line-height: 35px;

    &:hover{
      border: solid 1px $primary-color;
    }
  }
}
.project-description{
  color: $light-color;
}
.project-socials{
  a{
    text-transform: none;
    text-decoration: none;
    border: solid 1px #3F4040;
    font-family: 'Wild World';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    transition: all .4s;

    &:hover{
      border: solid 1px $primary-color;
    }

    span{
      position: relative;
      top: 3px;
      color: $light-color;
    }
  }
}

.counterContainer{
  color: #FFF;
  border-radius: 10px;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;

  .item{
    margin-left: .5rem;
    margin-right: .5rem;
  }
}

.project-features{
  .one-feature{

    background: rgba(100, 100, 100, 0.3);
    border-radius: 10px;
    border: solid 1px #3F4040;
    height: 100%;

    &:hover{
      border: solid 1px $primary-color;
    }

    h2{
      font-family: 'Helvetica Neue LT Std';
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      line-height: 140%;
      color: $light-color;
      letter-spacing: 0.02em;
    }

    h5{
      font-family: 'Helvetica Neue LT Std';
      font-style: normal;
      font-size: 24px;
      line-height: 29px;
      color: $primary-color;
      font-weight: 900;
    }

  }

}
.collabTerms{
  ul{
    list-style: none;
    padding: 0;
    color: $light-color;
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    white-space: pre-wrap;
    margin-bottom: 15px;

    @media (max-width: 768px){
      font-size: 15px;
    }

  }
}

#projectTypes{
  background: #171414;
  border: 1px solid #3F4040;
  border-radius: 20px;
  overflow: hidden;
}
.projectType{
  font-family: 'Helvetica Neue LT Std';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #949494;
  margin: 20px 20px;
  padding: 10px 20px;


  @media(max-width: 1024px){
    margin: 0px;
    font-size: 15px;
    text-align: center;
  }

  &.active{
    background: #2E2C2C;
    backdrop-filter: blur(3px);
    border-radius: 4px;
    color: $primary-color;
  }
  
  &:hover{
    cursor: pointer;
    color: $primary-color;
  }
}
#noProjects{
  height: 222px;
  width: 100%;
  background-size: 100% auto;
  background-repeat: no-repeat;
  color: $light-color;

  @media (max-width: 1024px) {
    background-size: auto 100%;

  }
  p{
    @media (max-width: 1024px) {
      font-size: 15px;
    }
  }
}