@import "../../common";



.team{
  background: #171414;
  border: 2px solid #3F4040;
  border-radius: 30px;
  color: $light-color;
  transition: all .4s;
  position: relative;

  &:hover{
    margin-top: -5px;
    box-shadow: 0px 4px 16px $primary-color;
  }

  .teamName{
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
  }

  .teamTitle{
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
  }

  .teamProfile{
    border-radius: 0px 20px 0px 0px;

  }

  .teamSocials{
    .social{
      background: $light-color;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;

      a{
        width: 100%;
        height: 100%;
        float: left;
      }

    }
  }
}