@import "custom";
@import "assets/fonts/fonts";
@import "common";


.App{
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5{
  font-family: "Wild World";
}

.text-bullrun-light{
  color: $light-color;
}

.bg-primary{
  background-color: $primary-color !important;
}

