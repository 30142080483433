@import "../../common";

.nav-link{
  color: $light-color;
  text-transform: uppercase;
  font-family: 'Helvetica Neue LT Std';
  font-style: normal;
  font-weight: 500;
  transition: color .3s;
  font-size: 20px;
  line-height: 17px;
  position: relative;

  &:hover{
    color: $primary-color;
  }

  @media (max-width: 1400px){
    font-size: 15px;
  }
  @media (max-width: 1200px){
    font-size: 14px;
  }

  span.soon{
    background: $red-color;
    color: $light-color;
    position: absolute;
    opacity: 0.7;
    top: -10px;
    left: -10px;
    padding: 5px 5px 3px;
    border-radius: 10px;
    font-size: 10px;
    line-height: 10px;
    @media(max-width: 1024px){
      left: unset;
    }
  }
}

.navbar-nav{
  @media(max-width: 1200px){
    text-align: center;

    .nav-link{
      margin-bottom: 15px;
      margin-top: 15px;
    }
  }
}
.navbar-toggler{
  background: $primary-color;;
  color: $light-color;
}
.dropdown-menu{
  background-color: $light-color;
}
.dropdown-item{
  &.active, &:active, &:hover, &:focus{
    background-color: $primary-color;
    color: $light-color;
  }
}
