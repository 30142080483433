@import "../../common";

.tokenSocials{
  a{
    color: #FFF;
    text-decoration: none;
    margin: .5rem;
    font-size: 20px;
  }
}
