@import "../../common";


.bullForV {
  top: -10px;
  position: relative;
}

.tokenValueDistribution {
  color: $light-color;

  .bg-dark{
    background: #000 !important;
  }

  .percentage {
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 24px;
    color: #000;
  }

  .label {
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 24px;
    color: #000;
  }

  .labelAndPercentage {
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #000;
    font-weight: 900;
  }

  .bg-dark{
    .labelAndPercentage {
      color: #FFF;
    }
  }

  .greenDot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $primary-color;
    display: inline-block;
    position: relative;
    top: -4px;
  }

  .extraStats {

    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: $light-color;

    >div{
      font-weight: 900;
    }
  }

}

.auditReport {
  border: solid 1px #3F4040;
  border-radius: 4px;
}

.downloadContainer {
  border: solid 1px $primary-color !important;
  border-right: 4px;

  font-family: 'Wild World';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  color: $primary-color;
  text-transform: uppercase;
  height: 84px;

  a{
    text-decoration: none;
    color: $primary-color;
    width: 100%;
    height: 100%;
    position: relative;
    float: left;
  }

  @media (max-width: 1024px) {
    font-size: 15px;
  }
}

.smartContract{
  .smTitle{
    font-family: 'Wild World';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;

    @media (max-width: 768px){
      font-size: 18px;
    }
  }
  .smAddress{
    ont-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;

    @media (max-width: 768px){
      font-size: 15px;
      margin-top: 10px;
    }
    a{
      color: #FFF;
      text-decoration: none;
    }

  }
}
.starSep{
  height: 40px;
  margin-right: 10px;
}
.copybtn{
  cursor: pointer;
  padding: 10px;
  margin-left: 5px;
  font-size: 15px;
  img{
    height: 20px;
    margin-right: 5px;
  }
}
