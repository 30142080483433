@import "../../common";


.bullRunmarquee{
  font-family: 'Wild World';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  color: $light-color;
  background: #000;
  border: solid 2px #3F4040;
  border-right: 0px;
  border-left: 0px;
  overflow: hidden;

  @media (max-width: 1024px){
    font-size: 15px;
  }

  .text-danger{
    color: $red-color !important;
  }
  .text-primary{
    color: $primary-color !important;
  }

  .overlay{
    --gradient-color: unset !important;
    --gradient-width: unset !important;
  }
  span{
    display: contents;
  }
}

.rotatingCoin{
  max-width: 500px;
  width: 100%;
}


