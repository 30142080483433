@import "../../common";

.buyBullRunHeader{
  font-size: 20px;
  font-weight: bolder;
  line-height: 25px;
}

.metamask{
  position: absolute;
  right: 0;
  top: -150px;
  max-width: 400px;
  @media (max-width: 1600px){
    width: 300px;
  }
  @media (max-width: 1280px){
   display: none;
  }
}
.bullRight{
  position: absolute;
  left: -100px;
  top: -20px;
  max-width: 300px;
  @media (max-width: 1600px){
    width: 300px;
  }
  @media (max-width: 1280px){
    display: none;
  }
}

.utility{
  color: $light-color;
  transition: all .4s;
  position: relative;
  height: 100%;

  .utilityNumber{
    font-size: 28px;
    font-family: "Wild World";
    background-color: $primary-color;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 80px;
    padding-left: 20px;
  }
  .utilityTitle{
    font-family: "Helvetica Neue LT Std";
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    -webkit-text-fill-color: $light-color;
    background: $dark-color;
  }
  .utilityDescription{
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    background: $dark-color;
    font-weight: 900;

    &.transparent{
      background-color: rgba(0,0,0, 0.6);
    }
  }
}

.sideRightBull{
  width: 400px;
  position: absolute;
  bottom: 0px;
  right: -200px;
  display: inline;
}
.sideLeftBull{
  width: 400px;
  position: absolute;
  bottom: 0px;
  left: -200px;
}
