@import "../../common";

.contactSocials{



  a{
    border-radius: 4px;
    width: 100%;
    height: 80px;
    color: $light-color;
    text-transform: uppercase;
    font-family: "Wild World";

    text-align: center;
    line-height: 80px;
    text-decoration: none;
    position: relative;
    float: left;
    transition: all .4s;

    &:hover{
      margin-top: -5px;
      margin-bottom: 5px;
    }

    @media (max-width: 768px){
      width: 100%;
      margin: 1% 0px ;
    }
  }
}

#contactForm{
  color: $light-color;
  border-radius: 10px;
  border: solid 1px #3F4040;

  h5{
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
  }

  textarea{
    resize: none;
  }
}
