@import "../../common";

#phases{
  h3{
    font-family: 'Helvetica Neue LT Std';
  }
  p{
    font-size: 14px;
    font-weight: 900;
    line-height: 15px;
  }
}
.roadmap{

  .roadTitle{
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: $light-color;
  }
  .roadMilestones{
    padding-left: 10px;
  }
  .roadMapMilestone{
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: $light-color;
  }
}
