@font-face {
    font-family: 'bagiquslant';
    src: url('bagiqu-bagiquslant-webfont.woff2') format('woff2'),
    url('bagiqu-bagiquslant-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'bagiquregular';
    src: url('bagiqu-regular-webfont.woff2') format('woff2'),
    url('bagiqu-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Wild World';
    src: url('WildWorldBOLD.woff2') format('woff2'),
    url('WildWorldBOLD.woff') format('woff'),
    url('WildWorldBOLD.svg#WildWorldBOLD') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-HvIt.woff2') format('woff2'),
        url('HelveticaNeueLTStd-HvIt.woff') format('woff'),
        url('HelveticaNeueLTStd-HvIt.svg#HelveticaNeueLTStd-HvIt') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-HvCnO.woff2') format('woff2'),
        url('HelveticaNeueLTStd-HvCnO.woff') format('woff'),
        url('HelveticaNeueLTStd-HvCnO.svg#HelveticaNeueLTStd-HvCnO') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-CnO.woff2') format('woff2'),
        url('HelveticaNeueLTStd-CnO.woff') format('woff'),
        url('HelveticaNeueLTStd-CnO.svg#HelveticaNeueLTStd-CnO') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-BdCnO.woff2') format('woff2'),
        url('HelveticaNeueLTStd-BdCnO.woff') format('woff'),
        url('HelveticaNeueLTStd-BdCnO.svg#HelveticaNeueLTStd-BdCnO') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-Hv.woff2') format('woff2'),
        url('HelveticaNeueLTStd-Hv.woff') format('woff'),
        url('HelveticaNeueLTStd-Hv.svg#HelveticaNeueLTStd-Hv') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-Blk.woff2') format('woff2'),
        url('HelveticaNeueLTStd-Blk.woff') format('woff'),
        url('HelveticaNeueLTStd-Blk.svg#HelveticaNeueLTStd-Blk') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-BlkEx.woff2') format('woff2'),
        url('HelveticaNeueLTStd-BlkEx.woff') format('woff'),
        url('HelveticaNeueLTStd-BlkEx.svg#HelveticaNeueLTStd-BlkEx') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-HvEx.woff2') format('woff2'),
        url('HelveticaNeueLTStd-HvEx.woff') format('woff'),
        url('HelveticaNeueLTStd-HvEx.svg#HelveticaNeueLTStd-HvEx') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-BlkCnO.woff2') format('woff2'),
        url('HelveticaNeueLTStd-BlkCnO.woff') format('woff'),
        url('HelveticaNeueLTStd-BlkCnO.svg#HelveticaNeueLTStd-BlkCnO') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-BdExO.woff2') format('woff2'),
        url('HelveticaNeueLTStd-BdExO.woff') format('woff'),
        url('HelveticaNeueLTStd-BdExO.svg#HelveticaNeueLTStd-BdExO') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-BlkExO.woff2') format('woff2'),
        url('HelveticaNeueLTStd-BlkExO.woff') format('woff'),
        url('HelveticaNeueLTStd-BlkExO.svg#HelveticaNeueLTStd-BlkExO') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-BlkIt.woff2') format('woff2'),
        url('HelveticaNeueLTStd-BlkIt.woff') format('woff'),
        url('HelveticaNeueLTStd-BlkIt.svg#HelveticaNeueLTStd-BlkIt') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-BlkEx.woff2') format('woff2'),
        url('HelveticaNeueLTStd-BlkEx.woff') format('woff'),
        url('HelveticaNeueLTStd-BlkEx.svg#HelveticaNeueLTStd-BlkEx') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-BlkIt.woff2') format('woff2'),
        url('HelveticaNeueLTStd-BlkIt.woff') format('woff'),
        url('HelveticaNeueLTStd-BlkIt.svg#HelveticaNeueLTStd-BlkIt') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-BlkCn.woff2') format('woff2'),
        url('HelveticaNeueLTStd-BlkCn.woff') format('woff'),
        url('HelveticaNeueLTStd-BlkCn.svg#HelveticaNeueLTStd-BlkCn') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-Bd.woff2') format('woff2'),
        url('HelveticaNeueLTStd-Bd.woff') format('woff'),
        url('HelveticaNeueLTStd-Bd.svg#HelveticaNeueLTStd-Bd') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-BlkCnO.woff2') format('woff2'),
        url('HelveticaNeueLTStd-BlkCnO.woff') format('woff'),
        url('HelveticaNeueLTStd-BlkCnO.svg#HelveticaNeueLTStd-BlkCnO') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-HvCn.woff2') format('woff2'),
        url('HelveticaNeueLTStd-HvCn.woff') format('woff'),
        url('HelveticaNeueLTStd-HvCn.svg#HelveticaNeueLTStd-HvCn') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-BdCn.woff2') format('woff2'),
        url('HelveticaNeueLTStd-BdCn.woff') format('woff'),
        url('HelveticaNeueLTStd-BdCn.svg#HelveticaNeueLTStd-BdCn') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-Ex.woff2') format('woff2'),
        url('HelveticaNeueLTStd-Ex.woff') format('woff'),
        url('HelveticaNeueLTStd-Ex.svg#HelveticaNeueLTStd-Ex') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-HvExO.woff2') format('woff2'),
        url('HelveticaNeueLTStd-HvExO.woff') format('woff'),
        url('HelveticaNeueLTStd-HvExO.svg#HelveticaNeueLTStd-HvExO') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-BlkCn.woff2') format('woff2'),
        url('HelveticaNeueLTStd-BlkCn.woff') format('woff'),
        url('HelveticaNeueLTStd-BlkCn.svg#HelveticaNeueLTStd-BlkCn') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-BdEx.woff2') format('woff2'),
        url('HelveticaNeueLTStd-BdEx.woff') format('woff'),
        url('HelveticaNeueLTStd-BdEx.svg#HelveticaNeueLTStd-BdEx') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-Blk.woff2') format('woff2'),
        url('HelveticaNeueLTStd-Blk.woff') format('woff'),
        url('HelveticaNeueLTStd-Blk.svg#HelveticaNeueLTStd-Blk') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-BdIt.woff2') format('woff2'),
        url('HelveticaNeueLTStd-BdIt.woff') format('woff'),
        url('HelveticaNeueLTStd-BdIt.svg#HelveticaNeueLTStd-BdIt') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-Cn.woff2') format('woff2'),
        url('HelveticaNeueLTStd-Cn.woff') format('woff'),
        url('HelveticaNeueLTStd-Cn.svg#HelveticaNeueLTStd-Cn') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-ExO.woff2') format('woff2'),
        url('HelveticaNeueLTStd-ExO.woff') format('woff'),
        url('HelveticaNeueLTStd-ExO.svg#HelveticaNeueLTStd-ExO') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-BlkExO.woff2') format('woff2'),
        url('HelveticaNeueLTStd-BlkExO.woff') format('woff'),
        url('HelveticaNeueLTStd-BlkExO.svg#HelveticaNeueLTStd-BlkExO') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-BdOu.woff2') format('woff2'),
        url('HelveticaNeueLTStd-BdOu.woff') format('woff'),
        url('HelveticaNeueLTStd-BdOu.svg#HelveticaNeueLTStd-BdOu') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-It.woff2') format('woff2'),
        url('HelveticaNeueLTStd-It.woff') format('woff'),
        url('HelveticaNeueLTStd-It.svg#HelveticaNeueLTStd-It') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-LtEx.woff2') format('woff2'),
    url('HelveticaNeueLTStd-LtEx.woff') format('woff'),
    url('HelveticaNeueLTStd-LtEx.svg#HelveticaNeueLTStd-LtEx') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-Lt.woff2') format('woff2'),
    url('HelveticaNeueLTStd-Lt.woff') format('woff'),
    url('HelveticaNeueLTStd-Lt.svg#HelveticaNeueLTStd-Lt') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-ThIt.woff2') format('woff2'),
    url('HelveticaNeueLTStd-ThIt.woff') format('woff'),
    url('HelveticaNeueLTStd-ThIt.svg#HelveticaNeueLTStd-ThIt') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-Roman.woff2') format('woff2'),
    url('HelveticaNeueLTStd-Roman.woff') format('woff'),
    url('HelveticaNeueLTStd-Roman.svg#HelveticaNeueLTStd-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-XBlkCnO.woff2') format('woff2'),
    url('HelveticaNeueLTStd-XBlkCnO.woff') format('woff'),
    url('HelveticaNeueLTStd-XBlkCnO.svg#HelveticaNeueLTStd-XBlkCnO') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-LtCnO.woff2') format('woff2'),
    url('HelveticaNeueLTStd-LtCnO.woff') format('woff'),
    url('HelveticaNeueLTStd-LtCnO.svg#HelveticaNeueLTStd-LtCnO') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-ThCn.woff2') format('woff2'),
    url('HelveticaNeueLTStd-ThCn.woff') format('woff'),
    url('HelveticaNeueLTStd-ThCn.svg#HelveticaNeueLTStd-ThCn') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-MdCn.woff2') format('woff2'),
    url('HelveticaNeueLTStd-MdCn.woff') format('woff'),
    url('HelveticaNeueLTStd-MdCn.svg#HelveticaNeueLTStd-MdCn') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-MdEx.woff2') format('woff2'),
    url('HelveticaNeueLTStd-MdEx.woff') format('woff'),
    url('HelveticaNeueLTStd-MdEx.svg#HelveticaNeueLTStd-MdEx') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-LtCn.woff2') format('woff2'),
    url('HelveticaNeueLTStd-LtCn.woff') format('woff'),
    url('HelveticaNeueLTStd-LtCn.svg#HelveticaNeueLTStd-LtCn') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-MdIt.woff2') format('woff2'),
    url('HelveticaNeueLTStd-MdIt.woff') format('woff'),
    url('HelveticaNeueLTStd-MdIt.svg#HelveticaNeueLTStd-MdIt') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-MdExO.woff2') format('woff2'),
    url('HelveticaNeueLTStd-MdExO.woff') format('woff'),
    url('HelveticaNeueLTStd-MdExO.svg#HelveticaNeueLTStd-MdExO') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-ThExO.woff2') format('woff2'),
    url('HelveticaNeueLTStd-ThExO.woff') format('woff'),
    url('HelveticaNeueLTStd-ThExO.svg#HelveticaNeueLTStd-ThExO') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-UltLtIt.woff2') format('woff2'),
    url('HelveticaNeueLTStd-UltLtIt.woff') format('woff'),
    url('HelveticaNeueLTStd-UltLtIt.svg#HelveticaNeueLTStd-UltLtIt') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-XBlkCn.woff2') format('woff2'),
    url('HelveticaNeueLTStd-XBlkCn.woff') format('woff'),
    url('HelveticaNeueLTStd-XBlkCn.svg#HelveticaNeueLTStd-XBlkCn') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-XBlkCn.woff2') format('woff2'),
    url('HelveticaNeueLTStd-XBlkCn.woff') format('woff'),
    url('HelveticaNeueLTStd-XBlkCn.svg#HelveticaNeueLTStd-XBlkCn') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-UltLtExO.woff2') format('woff2'),
    url('HelveticaNeueLTStd-UltLtExO.woff') format('woff'),
    url('HelveticaNeueLTStd-UltLtExO.svg#HelveticaNeueLTStd-UltLtExO') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-UltLt.woff2') format('woff2'),
    url('HelveticaNeueLTStd-UltLt.woff') format('woff'),
    url('HelveticaNeueLTStd-UltLt.svg#HelveticaNeueLTStd-UltLt') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-XBlkCnO.woff2') format('woff2'),
    url('HelveticaNeueLTStd-XBlkCnO.woff') format('woff'),
    url('HelveticaNeueLTStd-XBlkCnO.svg#HelveticaNeueLTStd-XBlkCnO') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-MdCnO.woff2') format('woff2'),
    url('HelveticaNeueLTStd-MdCnO.woff') format('woff'),
    url('HelveticaNeueLTStd-MdCnO.svg#HelveticaNeueLTStd-MdCnO') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-Th.woff2') format('woff2'),
    url('HelveticaNeueLTStd-Th.woff') format('woff'),
    url('HelveticaNeueLTStd-Th.svg#HelveticaNeueLTStd-Th') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-UltLtCn.woff2') format('woff2'),
    url('HelveticaNeueLTStd-UltLtCn.woff') format('woff'),
    url('HelveticaNeueLTStd-UltLtCn.svg#HelveticaNeueLTStd-UltLtCn') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-ThEx.woff2') format('woff2'),
    url('HelveticaNeueLTStd-ThEx.woff') format('woff'),
    url('HelveticaNeueLTStd-ThEx.svg#HelveticaNeueLTStd-ThEx') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-UltLtEx.woff2') format('woff2'),
    url('HelveticaNeueLTStd-UltLtEx.woff') format('woff'),
    url('HelveticaNeueLTStd-UltLtEx.svg#HelveticaNeueLTStd-UltLtEx') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-Md.woff2') format('woff2'),
    url('HelveticaNeueLTStd-Md.woff') format('woff'),
    url('HelveticaNeueLTStd-Md.svg#HelveticaNeueLTStd-Md') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-UltLtCnO.woff2') format('woff2'),
    url('HelveticaNeueLTStd-UltLtCnO.woff') format('woff'),
    url('HelveticaNeueLTStd-UltLtCnO.svg#HelveticaNeueLTStd-UltLtCnO') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-LtIt.woff2') format('woff2'),
    url('HelveticaNeueLTStd-LtIt.woff') format('woff'),
    url('HelveticaNeueLTStd-LtIt.svg#HelveticaNeueLTStd-LtIt') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-LtExO.woff2') format('woff2'),
    url('HelveticaNeueLTStd-LtExO.woff') format('woff'),
    url('HelveticaNeueLTStd-LtExO.svg#HelveticaNeueLTStd-LtExO') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-ThCnO.woff2') format('woff2'),
    url('HelveticaNeueLTStd-ThCnO.woff') format('woff'),
    url('HelveticaNeueLTStd-ThCnO.svg#HelveticaNeueLTStd-ThCnO') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('HelveticaNeueLTStd-XBlkCn.woff2') format('woff2'),
    url('HelveticaNeueLTStd-XBlkCn.woff') format('woff'),
    url('HelveticaNeueLTStd-XBlkCn.svg#HelveticaNeueLTStd-XBlkCn') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

