@import "../../common";

.disclaimer {
  box-shadow: 0px 4px 16px $primary-color;
  border-radius: 30px;
  background: rgba(0,0,0, 0.7);
  p{
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 24px !important;
    line-height: 140%;
    color: #FFF;
  }

  h1{
    color: $primary-color;
    @media (max-width: 1024px){
      font-size: 28px !important;
    }
  }
  strong{
    font-weight: 900;
  }
}

.socialTokens{
  &>div{

    padding-bottom: 50px;
  }
}
