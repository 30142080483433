@import "../../../common";

.oneProject{
  background: #171414;
  border: 1px solid #3F4040;
  border-radius: 30px;
  color: $light-color;

  .banner{
    height: 206px;
  }
}
.nameAndContact{

  h5, a, span, .projectDate{
    display: inline-block;
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    justify-content: center;
  }

  h5{
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 22px;
  }
}

.saleInfo{
  .oneSaleInfo{

    width: 46%;
    display: inline-block;
    margin: 0 4%;

    &:nth-child(odd){
      margin-left: 0px !important;
    }
    &:nth-child(even){
      margin-right: 0px !important;
    }

    h5{
      font-family: 'Helvetica Neue LT Std';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #676767;
      display: inline-block;
      float: left;
      text-align: left;
    }

    span{
      font-family: 'Helvetica Neue LT Std';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $light-color;
      float: right;
    }
  }
}
.detailsBtn{
  border: 1px solid $primary-color;
  border-radius: 5px;
  font-family: 'Helvetica Neue LT Std';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: $light-color;
  padding: 10px 45px;
  background: transparent;

  &:hover, &:focus{
    background: $primary-color;
    border: 1px solid $primary-color;
  }
}