@import "../../../common";

.teaser-container {
  width: 50vw;
  height: auto;
  position: -webkit-sticky;
  position: sticky;
  border: solid 1px $primary-color;
  border-radius: 10px;
  overflow: hidden;

  @media (max-width: 1200px){
    width: 100%;
  }
}

.video-toggle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.6);
    z-index: 9999;
    width: 100px;
    height: 100px;
    fill: #ffffff;
    opacity: 0;

    &:hover {
      cursor: pointer;
    }

    // scale: 0.6;
    // transition: all 2s;
    &.active {
      // scale: 1;
      opacity: 0.7;
      transition: all 0.6s;
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

.teaser-container video {
  width: 100%;
  position: relative;
}
.oneTeam{
  color: $light-color;

  &.col-md-2{
    width: 20% !important;

    @media(max-width: 1280px){
      width: 33% !important;
    }

  }

  h3{
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    @media(max-width: 990px){
      font-size: 20px;
    }
  }

  h5{
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    @media(max-width: 990px){
      font-size: 12px;
    }
  }
}
.helmetDiv{
  overflow: hidden;
}
.helmet{
  transition: all .4s;

  &:hover{
    margin-top: -10px;
  }
}
.eligibility{
  width: 14%;
  color: $light-color;

  @media (max-width: 1024px) {
    width: 33%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
}

.sectionSubTitle{
  color: #00B334 !important;
  @media (max-width: 1024px) {
    font-size: 13px;
  }
}
.terms{
  color: $light-color;
}

.saleDateDiv{
  background: rgba(0,0,0, 0.8);
  border-radius: 10px;
}