@import "../../common";

.copyright{
  font-family: 'Helvetica Neue LT Std';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: $light-color;

  a{
    color: $primary-color;
  }
}

.footerLinks{

  a{
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $light-color !important;
  }
}