@import "../../../common";

.connectingWalletLabel {
  font-family: 'Helvetica Neue LT Std';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: $light-color;
}
.migrationBox{
  .specialFont{
    font-family: "Helvetica Neue LT Std";
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 26px;
  }

}
.stakingBox {
  border-radius: 0px 10px 10px;
  border: solid 1px $primary-color;

  &.fullRadius{
    border-radius: 10px
  }
}
.mintBtn{
  font-size: 10px;
  border-radius: 4px;
  border: solid 1px $primary-color;
  background: transparent;
  font-family: "Helvetica Neue LT Std";
  text-transform: uppercase;
  &:hover, &:focus, &:active{
    background-color: transparent !important;
    border-color: $primary-color;
    box-shadow: 0 0 0 0.25rem rgba($primary-color, .5) !important;
  }
  &:disabled{
    background-color: transparent !important;
    border-color: rgba($primary-color, 0.6);
    box-shadow: 0 !important;
  }
}
.rewardsProgress{
  width: 100%;
  height: 20px;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 900;

  .progressContainer{
    width: 100%;
    height: 100%;
    border: solid 1px $primary-color;
    border-radius: 10px;
    overflow: hidden;
  }

  .innerProgress{
    height: 100%;
    width:  70%;
    color: $light-color;
    line-height: 20px;
    font-size: 15px;
    transition: all 1s ease;;
  }
}
.tokenBalances {
  .balance {
    color: $light-color;
    width: 50%;

    &.full{
      width: 100%;
    }

    .fiatPrice{
      font-family: 'Helvetica Neue LT Std';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #707070;
    }

    .fiatPrice.small{
      font-size: 15px;
      line-height: 18px;
    }

    h5 {

      font-family: 'Helvetica Neue LT Std';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
    h4{
      font-family: 'Helvetica Neue LT Std';
      font-style: normal;
      font-weight: 900;
      font-size: 22px;
      line-height: 26px;

      @media (max-width: 768px){
        font-size: 18px;
      }
    }

    &.general{

      &:first-child{
        width: 100%;
        h2 {

          font-family: 'Helvetica Neue LT Std';
          font-style: normal;
          font-weight: 400;
          font-size: 30px;
        }
        h4{
          font-family: 'Helvetica Neue LT Std';
          font-style: normal;
          font-weight: 900;
          font-size: 30px;
          line-height: 26px;

          @media (max-width: 768px){
            font-size: 25px;
          }
        }
      }

      h5 {
        font-size: 20px;
      }
      h4{
        font-size: 22px;
      }
    }
  }
}

.actionInputs{
  width: 50%;

  @media (max-width: 768px){
    width: 100%;
  }

  .input-group{
    background: transparent;
    border-radius: 4px;
    border: solid 1px $primary-color;

    button{
      font-family: 'Helvetica Neue LT Std';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      width: 36px;
      height: 21px;
      left: 6px;
      top: 6px;
      text-align: center;
      line-height: 10px;
      padding: 0;
      margin: 0;
      border-radius: 5px !important;
      background: #171414;
    }

    input{
      background: transparent;
      font-family: 'Helvetica Neue LT Std';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      border: 0px;
      outline: 0px;
      box-shadow: none;
      text-align: right;
      color: $light-color !important;
      -webkit-appearance: none;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button, &::-webkit-inner-spin-button{
        -webkit-appearance: none !important;
        -moz-appearance: textfield !important;
      }
    }
  }
}
.actionButtons{
  width: 50%;

  @media (max-width: 768px){
    width: 100%;
  }

  &.full{
    width: 100%;
  }
}

.switchButton{
  border-radius: 4px;
  border: solid 1px $primary-color;
  background: transparent;
  font-family: "Helvetica Neue LT Std";
  font-size: 27px;
  height: 37px;
  width: 50px;
  line-height: 25px;
  align-items: center;
  display: table-cell;
  vertical-align: middle;
  &:hover, &:focus, &:active{
    background-color: transparent !important;
    border-color: $primary-color;
    box-shadow: 0 0 0 0.25rem rgba($primary-color, .5) !important;
  }
  &:disabled{
    background-color: transparent !important;
    border-color: rgba($primary-color, 0.6);
    box-shadow: 0 !important;
  }

  &.full{
    width: auto;
    width: 124px;
    height: 37px;
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
}
.trxMessage{

  min-height: 30px;

  div{
    color: $light-color;
    font-family: "Helvetica Neue LT Std";
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
}

.stakingDescription{
  color: $light-color;
  font-family: 'Helvetica Neue LT Std';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;

  .bolden{
    font-weight: 500;
    font-size: 14px;
  }
}


.nav-tabs{
  border-bottom: 0px !important;
}
#stakingTabs-tab-brlStaking, #stakingTabs-tab-nftStaking{
  &.active{
    background-color: $primary-color;
    color: $light-color;
    border-color: $primary-color;
  }
  border-color: $primary-color;

}

#aprExplanation{
  max-width: 380px;
  /* If max-width does not work, try using width instead */
  width: 90%;
  font-size: 10px !important;
  .popover-body{
    height: 400px;
    overflow: scroll;
    background: #fff3e2;
  }
  .popover-header{
    background: #fff3e2;
  }
  pre{
    white-space: break-spaces;
  }
  p{
    font-size: 10px !important;
  }
}
.aprInfo{
  border-radius: 50%;
  border: solid 1px $primary-color;
  background: transparent;
  font-family: "Helvetica Neue LT Std";
  font-size: 15px;
  line-height: 10px;
  width: 20px;
  height: 20px;
  align-items: center;
  padding: 0;

  &:hover, &:focus{
    background-color: $primary-color;
    border-color: $primary-color;
    box-shadow: 0 0 0 0.25rem rgba($primary-color, .5) !important;
  }
}