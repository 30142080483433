@import "../../common";

.boosterNFTText{
  background: rgba(0,0,0,0.65);
  color: #FFF;

  p{
    font-size: 20px !important;
  }
}

