@import "../../../common";
.wallets{
	display: flex;
	justify-content: center;
}

.wallet{
	border: solid 1px #3F4040;
	border-radius: 4px;

	&:hover{
		border: solid 1px $primary-color;
	}

	span{
		font-family: 'Helvetica Neue LT Std';
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		color: $light-color
	}
}

.icon{
	background: #000000;
	border-radius: 50%;
	width: 45px;
	height: 45px;
	line-height: 45px !important;
	text-align: center;
}
p{
	font-size: 22px;
}
