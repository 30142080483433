@import "../../common";


.pieChart{
  z-index: 5;
  position: relative;
}

.tokenDistribution{
  color: $light-color;
  z-index: 4;
  position: relative;

  h2{
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
  }


  ul li{
    //list-style-image: url('../../assets/images/tokenomics-style.svg');
    list-style: none;
    transition: all .2s ease-in-out;
    margin-left: 0px;
    margin-bottom: 20px;
    padding-left: 5px;
    font-size: 25px;

    &.active{
      font-weight: bold;
      font-size: 25px;
      color: $primary-color;
    }
  }
}
