@import "../../common";

.dashboardSection{
  background: #171414;
  border: 1px solid #3F4040;
  border-radius: 30px;

  h1 {
    font-family: 'Wild World';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
    /* identical to box height */

    text-transform: uppercase;

    color: #FFF3E2;

    @media (max-width: 1024px) {
      font-size: 20px;
      line-height: 20px;
    }
  }
}

.statsContainer{
  margin-top: 9rem;
}

.assetsContainer {

  .header {

    div{
      color: #949494;
      font-family: 'Helvetica Neue LT Std';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 140%;
      @media (max-width: 1024px) {
        font-size: 18px;
        line-height: 100%;
      }
    }

  }

  .data{
    ont-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #FFF3E2;
    border-top: solid 1px #949494;
    display: flex;
    flex-direction: row;

    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 100%;
    }

    div{
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 110%;
      margin-bottom: .5rem;
      margin-top: .5rem;

      span{
        color: #949494;
        font-size: 20px;
        @media (max-width: 1024px) {
          font-size: 12px;
        }
      }

    }
  }

  .switch{
    background: #171414;
    border: 1px solid #3F4040;
    border-radius: 20px;

    .viewType{

      cursor: pointer;
      position: relative;

      @media (max-width: 1024px) {
        margin: 0;
        padding: 0 !important;
        border-radius: 20px 0px 0px 20px  !important
      }

      .soon{
        background: $red-color;
        color: $light-color;
        position: absolute;
        opacity: 0.7;
        top: -10px;
        right: -15px;
        padding: 5px 5px 3px;
        border-radius: 10px;
        font-size: 10px;
        line-height: 10px;
      }

      &.active{
        background: #2E2C2C;
        backdrop-filter: blur(3px);
        /* Note: backdrop-filter has minimal browser support */
        border-radius: 4px;
        color: $primary-color;
      }

      &.disabled{
        cursor: unset;
      }

    }
  }
}
.dataRow{
  h3{
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 140%;
    /* identical to box height, or 45px */


    color: #949494;
  }
  span{
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 750;
    font-size: 32px;
    line-height: 38px;
    display: flex;
    align-items: center;

    color: #FFF3E2;
  }
}
.totalBalance h5{
  font-family: 'Helvetica Neue LT Std';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 140%;
  color: #949494;

  @media (max-width: 1024px){
    font-size: 20px;
  }
}

#showHoldingSwitch{
  border-color: $primary-color;
  box-shadow: $primary-color;
}
label[for=showHoldingSwitch]{
  color: $light-color;
}
.form-switch .form-check-input:checked{
  background-color: $primary-color !important;
}
