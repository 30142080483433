$primary-color: #f5bf5e;
$red-color: #c7001a;
$light-color: #FFF3E2;
$dark-color: #050303;

.text-danger{
  color: $red-color !important;
}

.text-primary{
  color: $primary-color !important;
}

.navbar.bg-dark{
  background-color: #000 !important
}
.section{
  min-height: calc(100% - 90px);
  height: auto;
  margin-top: 90px;
  position: relative;

  @media (max-width: 768px) {
    height: auto;
  }
}

.section-two{
  height: calc(100% - 90px);
  margin-top: 90px;
  position: relative;

  @media (max-width: 768px) {
    height: auto;
  }
}

.section-continuous{
  min-height: calc(100% - 130px);
  height: auto;
  position: relative;

  @media (max-width: 768px) {
    height: auto;
  }
}

.sectionText2 {
  h4{

  }
}
.sectionText{
  h4{
    font-family: "Helvetica Neue LT Std";
    color: $primary-color;
    font-size: 30px;

    strong{
      font-weight: 900;
    }
  }
  .heroHeader{
    font-family: "Helvetica Neue LT Std";
    font-size: 34px !important;

    span{
      font-size: 35px;
      font-family: "Helvetica Neue LT Std";
      font-weight: lighter;

      b{
        font-weight: 500;
      }
    }
  }
  h1, h2{
    font-size: 70px;
    font-family: "bagiquregular";
    color: $primary-color;
    text-transform: uppercase;
    text-shadow:
            1px 1px 0px gold,
            15px 10px 10px black,
            0 0 40px gold;

    @media (max-width: 768px){
      font-size: 15px;

      span{
        font-size: 25px;
      }
    }
  }

  p{
    color: $light-color;
    font-family: "Helvetica Neue LT Std";
    font-weight: normal;
    font-weight: 400;
    font-size: 20px;

    @media (max-width: 768px){
      font-size: 15px !important;
    }
    &.normal{
      font-size: 20px;
    }
    &.moreNormal{
      font-size: 22px;
      font-weight: 500;
      line-height: 25px;
    }
  }
}
.withShadow{
  text-shadow: 10px 10px 10px black
}
.emptyButton{
  background: none !important;
  border: none !important;
}
.brandButton{
  min-width: 180px;
  height: 40px;
  border-radius: 100px;
  background-color: $primary-color;
  border-color: $primary-color;
  text-align: center;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.05em;
  color: $dark-color;
  font-family: 'Wild World';

  &:disabled{
    background-color: rgba($primary-color, 0.6) !important;
    border-color: rgba($primary-color, 0.6);
    box-shadow: 0 !important;
  }

  &.small{
    min-width: unset;
    width: 124px;
    height: 37px;
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }


  &:hover, &:focus{
    background-color: $primary-color;
    border-color: $primary-color;
    box-shadow: 0 0 0 0.25rem rgba($primary-color, .5) !important;
  }
  &:active{
    animation: press 0.2s 1 linear;
    background-color: $red-color !important;
    border-color: $red-color !important;

    img:last-child {
      animation: bounce 0.2s 1 linear;
    }
  }

  @media (max-width: 768px){
    width: 100%;
    height: 60px;
  }

}

@keyframes press {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.92);
  }
  to {
    transform: scale(1);
  }
}
@keyframes bounce {
  50% {
    transform: rotate(5deg) translate(20px, -50px);
  }
  to {
    transform: scale(0.9) rotate(10deg) translate(50px, -80px);
    opacity: 0;
  }
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
.animate-flicker {
  -webkit-animation: flickerAnimation .8s infinite;
  -moz-animation: flickerAnimation .8s infinite;
  -o-animation: flickerAnimation .8s infinite;
  animation: flickerAnimation .8s infinite;
}

@keyframes beat {
  0%, 50%, 100% { transform: scale(1, 1); }
  30%, 80% { transform: scale(0.92, 0.95); }
}

@keyframes pulse {
  0%, 50%, 100% {  background: #fee; }
  30%, 80% { background: #fff; }
}

.animate-beats{
  -webkit-animation: 1.5s ease 0s infinite beat;
  -moz-animation: 1.5s ease 0s infinite beat;
  -o-animation: 1.5s ease 0s infinite beat;
  animation: 1.5s ease 0s infinite beat;
}

.moveButton{}
