@import "../../../common";

.mintQuantityController{
  background: #171414;
  width: 150px;
  height: 40px;
  color: $light-color;
  border: 0px;

  input[type=number]{
    background: #171414;
    color: $light-color !important;
    border: 0px;
    text-align: center;
    -moz-appearance: textfield !important;
    outline: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button{
      -webkit-appearance: none !important;;
      margin: 0;
    }
  }

  .minQuantityControl{
    background: #171414;
    color: $light-color;
    border: 0px;
    cursor: pointer;
  }
}
