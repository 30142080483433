@import "../../common";


.faq.accordion{
  color: $light-color;

  .accordion-item{
    border-left: 0px;
    border-right: 0px;

    &:first-child{
      border-top: 0px;
    }
    &:last-child{
      border-bottom: 0px;
    }
  }

  .accordion-collapse{
    color: $light-color;
    background:  #000 !important;
    strong{
      font-weight: 900;
    }
  }


  h2 {


    button{
      font-family: 'Helvetica Neue LT Std' !important;
      font-style: normal;
      font-weight: 900;
      font-size: 40px;
      line-height: 140%;
      outline: none !important;
      box-shadow: none !important;
      transition: all .4s;

      color: $primary-color;
      background:  #000 !important;

      &::after{
        background: url('../../assets/images/bull-up.png');
      }

      &.collapsed{
        color: $light-color;
      }

      @media (max-width: 1024px){
        font-size: 30px;
      }
    }

  }
}