@import "../../common";

#scrollTop{
  position: fixed;
  bottom: 100px;
  right: 30px;
  z-index: 1000;
  cursor: pointer;
  transition: all .4s;
  display: none;

  img{
    width: 37px;
  }

  &:hover{
    bottom: 55px;
  }

  &.visible{
    display: block;
  }
}