@import "../../common";

.utilityPrimaryHeader{
  font-family: 'Helvetica Neue LT Std';
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;

}
.utilityGreenHeader{
  font-family: 'Helvetica Neue LT Std';
  font-size: 30px;
  font-weight: 900;
  background: #0edf03;
  text-transform: uppercase;
}


.utility{
  color: $light-color;
  transition: all .4s;
  position: relative;
  height: 100%;

  .utilityNumber{
    font-size: 28px;
    font-family: "Wild World";
    background-color: $primary-color;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 80px;
    padding-left: 20px;
  }
  .utilityTitle{
    font-family: "Helvetica Neue LT Std";
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    -webkit-text-fill-color: $light-color;
    background: $dark-color;
  }
  .utilityDescription{
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    background: $dark-color;
    font-weight: bold;

    &.transparent{
      background-color: rgba(0,0,0, 0.6);
    }
  }
}

.sideRightBull{
  width: 400px;
  position: absolute;
  bottom: 0px;
  right: -200px;
}
.sideLeftBull{
  width: 400px;
  position: absolute;
  bottom: 0px;
  left: -200px;
}
