@import "../../../common";

.calculator{

  input[type=number] {
    background: transparent;
    font-family: "Helvetica Neue LT Std";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    border: solid 1px $primary-color;
    outline: 0px;
    box-shadow: none;
    color: #FFF3E2 !important;
    -webkit-appearance: none;
  }
}