@import "../../common";

.leftBottomImg{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 300px;
}

.rightBottomImg{
  position: absolute;
  bottom: 0;
  right: -70px;
  width: 400px;
}
