@import "../../../common";

#participationBox{
  color: $light-color;
  background: #000;
  border: solid 1px $primary-color;
  box-shadow: 0 0 50px rgba($primary-color, .5) !important;
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  #boxBlocker{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    backdrop-filter: blur(5px);
    z-index: 15;
  }

  .rightBorder{
    border-right: solid 1px #4D4D4D;;

    @media(max-width: 1024px){
      border-right: 0px;
    }
  }
  .leftBorder{
    border-left: solid 1px #4D4D4D;
    @media(max-width: 1024px){
      border-left: 0px;
    }
  }

  .topBorder{
    @media(max-width: 1024px){
      border-top: solid 1px #4D4D4D;
    }
  }

  .sectionTitle{
    font-size: 25px;
    border-bottom: solid 1px #4D4D4D;
  }

  .balanceDiv{
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 140%;
    color: $primary-color;

    span{
      color: $light-color;
    }
  }

  input[type=email]{
    background: black;
    color: $light-color;
  }

  .requirements{
    a{
      color: #00B334;
      text-decoration: none;
    }

    h6{
      font-family: 'Helvetica Neue LT Std';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
      text-transform: uppercase;
    }
  }

  .preOrder{
    h6{
      font-family: 'Helvetica Neue LT Std';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
      text-transform: uppercase;
    }
  }

  .brandButton.small{
   font-family: "Wild World";
    width: auto;
    line-height: 28px;

    svg{
      margin-top: -5px;
      position: relative;
    }

    @media (max-width: 1204px){
      font-size: 10px;
    }
  }

  .progressLoader{
    background: $primary-color;
    width: 50%;
    height: 5px;
  }

  .emailInput.error{
    border: solid 1px $red-color;
  }

  .spotsController{
    background: #171414;
    width: 150px;
    height: 40px;
    color: $light-color;
    border: 0px;

    input[type=number]{
      background: #171414;
      color: $light-color !important;
      border: 0px;
      text-align: center;
      -moz-appearance: textfield !important;
      outline: none;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button{
        -webkit-appearance: none !important;;
        margin: 0;
      }
    }

    .spotsControl{
      background: #171414;
      color: $light-color;
      border: 0px;
      cursor: pointer;
    }
  }

  .consent{
    font-size: 10px;

    a{
      color: $primary-color !important;
      text-decoration: none;
    }
  }

}